<template>
  <v-dialog
    v-model="dialogBaixaVenda"
    @click:outside="fecharBaixaVenda"
    @keydown.esc="fecharBaixaVenda"
    width="70vw"
    scrollable
  >
    <v-card tile>
      <!-- HEADE DIALOG -->
      <v-card-title class="px-6 tocs_gray_1">
        <span class="">
          {{ $tc("global.venda") }}
        </span>
        <span class="mx-2">{{ venda.numero + " - " }}</span>
        <span class="">
          {{
            venda.condicao_pagamento === 2
              ? $tc("global.aPrazo")
              : $tc("global.aVista")
          }}
        </span>

        <v-spacer></v-spacer>

        <span class="pr-4">
          {{ venda.data | dateFormat("dd/MM/yyyy") }}
        </span>

        <v-btn icon @click="fecharBaixaVenda">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <!-- CORPO DIALOG -->
      <v-card-text class="pa-6">
        <!-- DADOS DO CLIENTE COM DEPOSITO VALOR E DESCONTO -->
        <v-card width="100%" flat>
          <v-card-title class="pa-0">
            {{ $tc("global.dados") + " " + $tc("global.cliente") }}
          </v-card-title>
          <v-card-text class="px-0 pt-2">
            <v-row class="d-flex justify-space-between">
              <v-col cols="12" md="4">
                <div>
                  <span class="text-h6 font-weight-bold">
                    {{ $tc("global.cliente") }}:
                  </span>
                  <span class="ml-2 black--text text-body-1">{{
                    venda.cliente_nome
                  }}</span>
                </div>
                <div>
                  <span class="text-h6 font-weight-bold">
                    {{ $tc("global.tipo") + " " + $tc("global.venda") }}:
                  </span>
                  <span class="ml-2 black--text text-body-1">{{
                    venda.tipo_venda
                  }}</span>
                </div>
                <div>
                  <span>
                    <span class="text-h6 font-weight-bold"
                      >{{ $tc("global.valor") + " " + $tc("global.total") }}:
                    </span>
                    <span
                      v-if="venda.moeda_sigla === 'G$'"
                      class="black--text text-body-1"
                    >
                      {{ venda.moeda_sigla }} {{ venda.valor | guarani }}
                    </span>
                    <span v-else class="black--text text-body-1">
                      {{ venda.moeda_sigla }} {{ venda.valor | currency }}
                    </span>
                  </span>
                </div>
              </v-col>
              <v-col cols="12" md="4">
                <div>
                  <span class="text-h6 font-weight-bold">
                    {{ $tc("global.vendedor") }}:
                  </span>
                  <span class="ml-2 black--text text-body-1">{{
                    venda.vendedor
                  }}</span>
                </div>
                <div>
                  <span class="text-h6 font-weight-bold">
                    {{ $tc("global.pagamento") }}:
                  </span>
                  <span class="black--text text-body-1">
                    {{
                      venda.condicao_pagamento === 2
                        ? $tc("global.aPrazo")
                        : $tc("global.aVista")
                    }}
                  </span>
                </div>
                <div>
                  <span class="text-h6">
                    <span class="text-h6 font-weight-bold"
                      >{{ $tc("global.desconto") }}:
                    </span>
                    <span
                      v-if="venda.moeda_sigla === 'G$'"
                      class="black--text text-body-1"
                    >
                      {{ venda.moeda_sigla }} {{ venda.desconto | guarani }}
                    </span>
                    <span v-else class="black--text text-body-1">
                      {{ venda.moeda_sigla }} {{ venda.desconto | currency }}
                    </span>
                  </span>
                </div>
              </v-col>
              <v-col cols="12" md="4" class="d-flex align-end">
                <div>
                  <div>
                    <span class="text-h6 font-weight-bold"
                      >{{ $tc("global.deposito") }}:
                    </span>
                    <span class="black--text text-body-1">
                      {{ venda.deposito_nome }}
                    </span>
                  </div>
                  <div>
                    <span>
                      <span class="text-h6 font-weight-bold"
                        >{{ $tc("global.valorComDesconto") }}:
                      </span>
                      <span
                        v-if="venda.moeda_sigla === 'G$'"
                        class="black--text text-body-1"
                      >
                        {{ venda.moeda_sigla }} {{ venda.total | guarani }}
                      </span>
                      <span v-else class="black--text text-body-1">
                        {{ venda.moeda_sigla }} {{ venda.total | currency }}
                      </span>
                    </span>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <!-- QUANDO FOR PARCELADO -->
        <v-divider v-if="venda.condicao_pagamento == 2"></v-divider>
        <v-data-table
          v-if="venda.condicao_pagamento == 2"
          :headers="headers_parcelas"
          :items="venda_parcelas"
          :items-per-page="-1"
          :sort-desc="true"
          class=""
          hide-default-footer
        >
          <template v-slot:item.numero_parcela="{ item }">
            <span v-if="item.entrega_inicial"> Entrega inicial </span>
            <span v-else>
              {{ item.numero_parcela }}
            </span>
          </template>

          <template v-slot:item.total_parcelas="{ item }">
            <span v-if="!item.entrega_inicial">
              {{ item.total_parcelas }}
            </span>
          </template>

          <template v-slot:item.vencimento="{ item }">
            <span v-if="!item.entrega_inicial">
              {{ item.vencimento | dateFormat("dd/MM/yyyy") }}
            </span>
          </template>

          <template v-slot:item.valor="{ item }">
            <span v-if="venda.moeda_sigla === 'G$'">
              {{ venda.moeda_sigla }} {{ item.valor | guarani }}
            </span>
            <span v-else>
              {{ venda.moeda_sigla }} {{ item.valor | currency }}
            </span>
          </template>
        </v-data-table>
        <v-divider
          class="mb-4"
          v-if="venda.condicao_pagamento == 2"
        ></v-divider>

        <span class="text-subtitle-1 font-weight-bold">Produtos</span>

        <!-- VENDA ITENS-->
        <v-divider></v-divider>
        <v-data-table
          :headers="headers_produtos"
          :items="venda.venda_itens"
          :items-per-page="-1"
          :sort-desc="true"
          class=""
          hide-default-footer
        >
          <template v-slot:item.produto_image="{ item }">
            <div class="my-1">
              <v-avatar v-if="item.produto_image">
                <v-img max-width="80px" :src="item.produto_image"></v-img>
              </v-avatar>
              <v-avatar v-else>
                <v-img
                  max-width="80px"
                  src="@/assets/product-placeholder.jpg"
                ></v-img>
              </v-avatar>
            </div>
          </template>
          <template v-slot:item.subtotal="{ item }">
            {{ venda.moeda_sigla }}
            <span v-if="venda.moeda_sigla === 'G$'">
              {{ (item.preco * item.qtde) | guarani }}
            </span>
            <span v-else>
              {{ (item.preco * item.qtde) | currency }}
            </span>
          </template>
          <template v-slot:item.preco="{ item }">
            {{ venda.moeda_sigla }}
            <span v-if="venda.moeda_sigla === 'G$'">
              {{ item.preco | guarani }}
            </span>
            <span v-else>
              {{ item.preco | currency }}
            </span>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions class="mr-4 pa-4">
        <v-card
          flat
          class="font-weight-bold ml-2 d-flex pa-0 py-1 align-center pr-3"
        >
          {{ $tc("global.gerarFatura") }}
          <v-switch
            class="ma-0 ml-3 pa-0"
            hide-details
            :false-value="false"
            :true-value="true"
            :disabled="forceGerarFatura ? true : false"
            v-model="gerarFatura"
          ></v-switch>
        </v-card>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialogDevolucao" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="button_2"
              class="white--text"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon left>mdi-arrow-u-left-top</v-icon>
              {{ $tc("global.retornarVendedor") }}
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <v-icon left>mdi-alert</v-icon>
              {{ $tc("global.desejaDevolver") }}
            </v-card-title>

            <v-card-text>
              <v-text-field
                v-model="motivo_devolucao"
                :label="$tc('global.motivo') + ' ' + $tc('global.devolucao')"
                outlined
                required
              ></v-text-field>
              <span class="text-caption">
                {{ $tc("global.mincaracteres", 10) }}
              </span>
            </v-card-text>

            <v-card-actions>
              <v-btn color="error" text @click="dialogDevolucao = false">
                {{ $tc("global.cancelar") }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="button_2"
                :disabled="semMotivo"
                class="white--text"
                @click="retornarVenda"
              >
                <v-icon left>mdi-arrow-u-left-top</v-icon>
                {{ $tc("global.devolver") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-btn
          @click.stop="salvarVendaBaixa"
          :disabled="faturarDisabled"
          :loading="loading"
          class="white--text"
          color="button_1"
        >
          <v-icon left>mdi-check</v-icon>
          {{ $tc("global.confirmar") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { fechaBaixaVenda, retornaVenda } from "@/api/vendas/vendas.js";
import { index } from "@/api/empresas/empresa_variaveis.js";
import { mapState } from "vuex";

export default {
  name: "DialogBaixaVendaEmLoteAf",

  props: {
    dialogBaixaVenda: {
      type: Boolean,
      default: false,
    },
    venda: {
      type: Object,
    },
    venda_parcelas: {
      type: Array,
    },
  },

  data() {
    return {
      loading: false,
      dialogDevolucao: false,
      motivo_devolucao: "",
      gerarFatura: false,
      formas_pagamentos: [],
      selectedFormaPagamento: null,
      totalRestante: null,
      fatura_id: null,
      variaveis: [],
      variavel_obrigatorio_fatura: {},
    };
  },

  computed: {
    caixa_id() {
      return this.$route.params.caixa_id;
    },

    ...mapState("CaixaAf", {
      caixa_af: (state) => state.caixa_af,
    }),

    headers_parcelas() {
      return [
        {
          text: this.$tc("global.numero") + " " + this.$tc("global.parcela"),
          value: "numero_parcela",
        },

        {
          text: this.$tc("global.total") + " " + this.$tc("global.parcela", 2),
          value: "total_parcelas",
        },
        {
          text: this.$tc("global.vencimento"),
          value: "vencimento",
        },
        {
          text: this.$tc("global.valor") + " " + this.$tc("global.parcela"),
          value: "valor",
        },
      ];
    },

    headers_produtos() {
      return [
        {
          text: "",
          value: "produto_image",
        },
        {
          text: this.$tc("global.produto"),
          value: "descricao",
        },
        {
          text: this.$tc("global.qtd"),
          value: "qtde",
        },
        {
          text: this.$tc("global.preco"),
          value: "preco",
        },
        {
          text: "Subtotal",
          value: "subtotal",
        },
      ];
    },

    semMotivo() {
      let result = false;

      if (
        this.motivo_devolucao === "" ||
        this.motivo_devolucao === null ||
        this.motivo_devolucao === undefined ||
        this.motivo_devolucao.length < 10
      ) {
        result = true;
      }

      return result;
    },

    faturarDisabled() {
      let result = false;

      if (this.totalRestante >= 0.01) {
        result = true;
      }
      return result;
    },

    forceGerarFatura() {
      let result = false;

      this.formas_pagamentos.find((forma_pagamento) => {
        if (
          forma_pagamento.forma_de_pagamento_id === 6 ||
          forma_pagamento.forma_de_pagamento_id === 7
        ) {
          result = true;
        }
      });

      if (
        this.venda.condicao_pagamento === 2 ||
        this.variavel_obrigatorio_fatura.valor === "1"
      ) {
        result = true;
      }

      return result;
    },
  },

  watch: {
    forceGerarFatura() {
      if (this.forceGerarFatura) {
        this.gerarFatura = true;
      }
    },
  },

  methods: {
    fecharDialog() {
      this.$emit("update:dialogBaixaVenda", false);
      this.$emit("fetch-vendas-caixa");
    },

    fecharBaixaVenda() {
      this.$emit("update:dialogBaixaVenda", false);
      fechaBaixaVenda(this.venda.id)
        .then(() => {
          this.$emit("fetch-vendas-caixa");
        })
        .catch(() => {});
    },

    retornarVenda() {
      let devolucao = {};
      devolucao.motivo = this.motivo_devolucao;

      retornaVenda(this.venda.id, devolucao)
        .then(() => {
          this.$emit("update:dialogBaixaVenda", false);
          this.$emit("fetch-vendas-caixa");
        })
        .catch(() => {});
    },

    async salvarVendaBaixa() {
      let venda = {
        id: this.venda.id,
        tipo: "venda",
        cliente_fornecedor: this.venda.cliente_nome,
        cliente: { id: this.venda.cliente_id, nome: this.venda.cliente_nome },
        condicao_pagamento: this.venda.condicao_pagamento,
        valor: this.venda.total,
        tipo_af: this.venda.condicao_pagamento === 2 ? "N" : "E",
        moeda_sigla: this.venda.moeda_sigla,
        numero: this.venda.numero,
        gerar_fatura: this.gerarFatura,
        tipo_venda: this.venda.tipo_venda,
      };  
      this.$emit("update:dialogBaixaVenda", false);
      this.$emit("fecharVendas");
      this.$emit("add", venda);
    },
  },

  async mounted() {
    this.variaveis = await index(`?empresa_id=${this.venda.empresa_id}`);
    this.variavel_obrigatorio_fatura = this.variaveis.find(
      (variavel) => variavel.campo === "OBRIGATORIO_EMISSAO_FATURA"
    );
    if (
      this.venda.condicao_pagamento === 2 ||
      this.variavel_obrigatorio_fatura.valor === "1"
    ) {
      this.gerarFatura = true;
    }
  },
};
</script>

<style></style>
